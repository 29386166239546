import React from 'react'
import AppContextProvider from "./context/AppContext.jsx"
import AppRouter from "./router/AppRouter.jsx"
import { FloatingWhatsApp } from 'react-floating-whatsapp'

const App = () => {
  return (
    <AppContextProvider>
      <AppRouter/>
      <FloatingWhatsApp
      phoneNumber="5444673419"
        accountName="Müşteri Temsilcisi"
        chatMessage='Size nasıl yardımcı olabiliriz?'
        darkMode="true"
        avatar='images/logo3.png'
        statusMessage=''
        placeholder='Mesajınızı yazınız...'
      
    />
    </AppContextProvider>

  )
}

export default App