import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { HiOutlineMail } from "react-icons/hi";
import { FaPhone } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaUser } from "react-icons/fa";

const Contact = () => {
  return (
    <div className="navbar-bg">
      <Navbar />
      <div className="bg-slate-200 pt-[120px]">
        <div className="min-h-[calc(100vh-236px)] px-3 pt-10 lg:pb-10  flex flex-col lg:flex-row items-center justify-center max-w-[1200px] mx-auto">
          <div className="lg:w-4/12 text-[#063454] font-semibold pb-5 lg:pb-0 ">
            <div>
            <h3 className="text-[#063454] font-bold  text-md py-3 sm:w-[450px] m-auto">
              İLETİŞİM BİLGİLERİ
            </h3>
            <div className="flex justify-start items-center gap-3 sm:w-[450px] m-auto">
              <FaLocationDot className="text-xl text-[#e0ae77] " />
              <p className="text-sm ">
                Orhan Gazi Mah. 18. Yol Sk. Isıso San. Sit. R1 Blok No:20 <br />
                <span className="block">ESENYURT/İSTANBUL</span>
              </p>
            </div>
            <div className="flex justify-start items-center gap-3 py-2 sm:w-[450px] m-auto">
              <FaPhone className="text-xl text-[#e0ae77]" />
              <div className="text-sm ">
                <p>0544 467 34 19</p>
              </div>
            </div>
            <div className="flex justify-start items-center gap-3 sm:w-[450px] m-auto">
              <HiOutlineMail className="text-xl text-[#e0ae77]" />
              <div className="text-sm">
                <a href="mailto:info@kircaliplastik.com" className="block">
                  info@kircaliplastik.com
                </a>
                <a href="mailto:sadullahsenturk@kircaliplastik.com" className="block">
                sadullahsenturk@kircaliplastik.com
                </a>
              </div>
            </div>
            </div>
            <div>
            <h3 className="text-[#063454] font-bold  text-md py-3 sm:w-[450px] m-auto">CONTACT US</h3>
            <div className="flex justify-start items-center gap-3 sm:w-[450px] m-auto">
              <FaUser className="text-xl text-[#e0ae77] " />
              <p className="text-sm">
                Nuriye AKGÜL
              </p>
            </div>
            <div className="flex justify-start items-center gap-3 py-2 sm:w-[450px] m-auto">
              <FaPhone className="text-xl text-[#e0ae77]" />
              <div className="text-sm">
                <p>+44 7495 5821 23 </p>
              </div>
            </div>
            <div className="flex justify-start items-center gap-3 sm:w-[450px] m-auto">
              <HiOutlineMail className="text-xl text-[#e0ae77]" />
              <div className="text-sm">
                <a href="mailto:nuriyeakgul@kircaliplastik.com" className="block">
                nuriyeakgul@kircaliplastik.com
                </a>
              </div>
            </div>
            <h3 className="text-[#063454] font-bold  text-md py-3 sm:w-[450px] m-auto">
              PAZARLAMA BÖLÜMÜ
            </h3>
            <div className="flex justify-start items-center gap-3 sm:w-[450px] m-auto">
              <FaUser className="text-xl text-[#e0ae77] " />
              <p className="text-sm ">
                Özgür YILMAZ
              </p>
            </div>
            <div className="flex justify-start items-center gap-3 py-2 sm:w-[450px] m-auto">
              <FaPhone className="text-xl text-[#e0ae77]" />
              <div className="text-sm ">
                <p>0552 316 21 82</p>
              </div>
            </div>
            <div className="flex justify-start items-center gap-3 sm:w-[450px] m-auto">
              <HiOutlineMail className="text-xl text-[#e0ae77]" />
              <div className="text-sm">
                <a href="mailto:ozguryilmaz@kircaliplastik.com" className="block">
                ozguryilmaz@kircaliplastik.com
                </a>
              </div>
            </div>
            </div>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d751.7610012206696!2d28.640511046812026!3d41.089885153773984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b5591bbc91f2b7%3A0x9e0eb1c66dbe7791!2sK%C4%B1rcal%C4%B1%20Plast%C4%B1k%20ve%20Kimya%20Paz.San.Tic.Ltd!5e0!3m2!1sen!2str!4v1694696042973!5m2!1sen!2str"
            width="400"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            className="border-0 m-auto w-full lg:max-w-[600px] "
          ></iframe>
        </div>
      </div>
    
      <Footer />
    </div>
  );
};

export default Contact;
