import React from "react";
import { HiOutlineMail } from "react-icons/hi";
import { FaPhone } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className=" py-6 px-12  bg-slate-600">
      <div className="flex flex-col-reverse sm:flex-row justify-between items-center">
        
     
      <div>
        <div className="flex justify-start items-center gap-2 ">
          <FaLocationDot className="text-lg text-yellow-600 " />
          <p className="text-sm text-white">Orhan Gazi Mah. 18. Yol Sk. Isıso San. Sit. R1 Blok No:20<br/>ESENYURT/İSTANBUL</p>
        </div>
        <div className="flex justify-start items-center gap-2 py-2">
          <FaPhone className="text-lg text-yellow-600" />
          <div className="text-sm text-white">
            <p>0544 467 34 19</p>
            {/* <a href="https://www.instagram.com/kircaliplastikk/">insta</a> */}
          </div>
        </div>
        <div className="flex justify-start items-center gap-2">
          <HiOutlineMail className="text-xl text-yellow-600" />
          <div className="text-sm">
            <a
              href="mailto: info@kircaliplastik.com"
              className="block text-white"
            >
                  info@kircaliplastik.com
            </a>
          </div>
        </div>
      </div>

      <NavLink to="/" className="text-yellow-600 logo text-xl pb-3 sm:pb-0 text-center font-bold">
      <img src="/images/logo2.png" className="w-[120px] rounded-[20px]"/>
      </NavLink>
      </div>
      <p className="text-white text-center py-3">Bizi Sosyal Medya Hesaplarımızdan Takip Edebilirsiniz</p>
      <div className="flex justify-center items-center">
        
        <a href="https://www.facebook.com/Kircaliplastik?mibextid=ZbWKwL"  target="_blank" className="rounded-full bg-[#316FF6] flex justify-center items-center w-full max-w-[32px] p-2 mx-2 hover:scale-125 transition-transform duration-400 ease-linear"><FaFacebookF className="text-white "/></a>
        <a href="https://www.instagram.com/kircaliplastikk/" target="_blank" className="rounded-full instagram flex justify-center items-center w-full max-w-[32px] p-2 mx-2 hover:scale-125 transition-transform duration-400 ease-linear"><FaInstagram className="text-white "/></a>
        <a href="https://twitter.com/Kircaliplastik?t=lxXc3iq0Q8tiQ8yDSszWjA&s=09" target="_blank" className="rounded-full bg-black flex justify-center items-center w-full max-w-[32px] p-2 mx-2 hover:scale-125 transition-transform duration-400 ease-linear"><FaXTwitter className="text-white "/></a>
        <a href="https://www.linkedin.com/company/kircali-plasti%CC%87k-ve-ki%CC%87mya-paz-san-ti%CC%87c-ltd-%C5%9Fti%CC%87/" target="_blank" className="rounded-full bg-[#0077b5] flex justify-center items-center w-full max-w-[32px] p-2 mx-2 hover:scale-125 transition-transform duration-400 ease-linear"><FaLinkedinIn className="text-white  "/></a>
      </div>
    </footer>
  );
};

export default Footer;


