import React from 'react'
import {IoChevronBackOutline} from 'react-icons/io5';


const LeftArrow = ({onClick}) => {
  return (
    <div className='flex absolute left-[0px] top-[50%] translate-y-[-50%] w-fit z-40'>
      <button className='text-[70px] font-bold' onClick={onClick}>
        {/* <IoChevronBackOutline className='text-[#063454]'/> */}
        <IoChevronBackOutline className='text-yellow-600'/>

      </button>
    </div>
  )
}

export default LeftArrow