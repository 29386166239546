import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaTimes } from "react-icons/fa";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [colorChange, setColorchange] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);

  const openNavbar = () => {
    setOpenMenu(!openMenu);
  };
  const changeNavbarColor = () => {
    if (window.scrollY >= 20) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  return (
    <nav className={`${
      colorChange
        ? `navbar_scroll container fixed left-[50%] top-3 translate-x-[-50%] py-3 px-5 z-50 ${
            openMenu ? "rounded-tl-[40px] rounded-tr-[40px]" : "rounded-[40px] transition-all duration-500 ease-linear"
          }`
        : `bg-transparent w-full fixed left-[50%] top-0 translate-x-[-50%] z-50 transition-all duration-300 ease-out px-5 py-2 ${
          openMenu ? "navbar_scroll" : "bg-transparent transition-all duration-500 ease-linear"
        }`
    }`}>

      <div className=" flex flex-wrap items-center justify-between mx-auto  w-full">
        <NavLink
          to="/"
          className="text-yellow-600  logo flex items-center justify-center text-center font-bold"
        >
          <img
            src="/images/logo2.png"
            className={`${
              colorChange ? "w-[80px]" : "w-[80px] md:w-[120px]"
            } rounded-[20px]`}
          />
        </NavLink>
        {!openMenu ? (
          <button
            className=" lg:hidden text-3xl text-yellow-600"
            aria-controls="navbar-default"
            aria-expanded="false"
            onClick={openNavbar}
          >
            <GiHamburgerMenu />{" "}
          </button>
        ) : (
          <button
            className="  text-3xl text-yellow-600 lg:hidden"
            aria-controls="navbar-default"
            aria-expanded="false"
            onClick={openNavbar}
          >
            <FaTimes />
          </button>
        )}

        <div className="hidden w-full lg:block md:w-auto" id="navbar-default">
          <ul className="font-medium h-full  p-4  mt-4  md:space-x-8 md:mt-0 md:border-0 flex justify-around items-center gap-3">
            <li>
              <NavLink
                to="/"
                className="text-yellow-600   font-bold navbar_link relative transition-all duration-300 ease-linear"
              >
                Anasayfa
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/hakkimizda"
                className="text-yellow-600  font-bold navbar_link relative transition-all duration-300 ease-linear"
              >
                Hakkımızda
              </NavLink>
            </li>
            <li
              className="relative"
              onMouseOver={() => setOpenDropdown(true)}
              onMouseLeave={() => setOpenDropdown(false)}
            >
              <NavLink
                to="/urunlerimiz"
                className="text-yellow-600 font-bold navbar_link relative transition-all duration-300 ease-linear"
              >
                Ürünlerimiz
              </NavLink>
              {openDropdown && (
                <div className="absolute left-[50%] top-5 translate-x-[-50%] pt-3">
                  <div className=" w-[180px] text-center rounded-xl py-1 dropdown_menu px-2">
                    <NavLink className="text-white hover:scale-105 cursor-pointer hover:bg-yellow-600 block rounded-[10px]"
                    to="/madeni_yag_plastik">
                      Madeni Yağ Plastik
                    </NavLink>
                    <NavLink className="text-white hover:scale-105 cursor-pointer hover:bg-yellow-600 block rounded-[10px]"
                    to="/zirai_plastik"
                    >
                      Zirai Platik Şişeleri
                    </NavLink>
                    <NavLink className="text-white hover:scale-105 cursor-pointer hover:bg-yellow-600 block rounded-[10px]"
                    to="/endustriyel_plastik">
                      Endüstriyel Plastik
                    </NavLink>
                    {/*<NavLink className="text-white hover:scale-105 cursor-pointer hover:bg-yellow-600 block"
                    to="/gida_plastik">
                      Gıda Plastik Şişeleri
                    </NavLink>*/}
                  </div>
                </div>
              )}
            </li>
            {/* <li >
              <NavLink to="/ekibimiz" className="text-yellow-600 font-bold navbar_link relative transition-all duration-300 ease-linear">
                Ekibimiz
              </NavLink>
            </li> */}
            <li>
              <NavLink
                to="/iletisim"
                className="text-yellow-600 font-bold navbar_link relative transition-all duration-300 ease-linear"
              >
                İletişim
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div
        className={`mobile-menu  flex flex-col justify-between mx-auto w-full   px-8 absolute top-[98%] left-0  z-50 lg:hidden 
        ${openMenu ? "h-[260px] pb-4 md:pb-1" : "h-0"}`}
      >
        <div className=" w-full h-full" id="navbar-default">
          <ul className="font-medium flex flex-col items-center justify-center gap-2  md:p-0 mt-4   md:mt-0 md:border-0 w-full">
            <li>
              <NavLink
                to="/"
                className="text-yellow-600  font-bold navbar_link relative transition-all duration-300 ease-linear"
                onClick={() => setOpenMenu(false)}
              >
                Anasayfa
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/hakkimizda"
                className="text-yellow-600  font-bold navbar_link relative transition-all duration-300 ease-linear"
                onClick={() => setOpenMenu(false)}
              >
                Hakkımızda
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/urunlerimiz"
                className="text-yellow-600  font-bold navbar_link relative transition-all duration-300 ease-linear"
                onClick={() => setOpenMenu(false)}
              >
                Ürünlerimiz
              </NavLink>
            </li>
            <div className="">
                  <div className=" w-[180px] text-center rounded-xl py-1 dropdown_menu px-2">
                    <NavLink className="text-white hover:scale-105 cursor-pointer hover:bg-yellow-600 block rounded-[10px]"
                    to="/madeni_yag_plastik">
                      Madeni Yağ Plastik
                    </NavLink>
                    <NavLink className="text-white hover:scale-105 cursor-pointer hover:bg-yellow-600 block rounded-[10px]"
                    to="/zirai_plastik"
                    >
                      Zirai Platik Şişeleri
                    </NavLink>
                    <NavLink className="text-white hover:scale-105 cursor-pointer hover:bg-yellow-600 block rounded-[10px]"
                    to="/endustriyel_plastik">
                      Endüstriyel Plastik
                    </NavLink>
                    {/* <NavLink className="text-white hover:scale-105 cursor-pointer hover:bg-yellow-600 block"
                    to="/gida_plastik">
                      Gıda Plastik Şişeleri
                    </NavLink> */}
                  </div>
                </div>
            {/* <li>
              <NavLink to="/ekibimiz" className="text-yellow-600 font-bold navbar_link relative transition-all duration-300 ease-linear">
                Ekibimiz
              </NavLink>
            </li> */}
            <li>
              <NavLink
                to="/iletisim"
                className="text-yellow-600  font-bold navbar_link relative transition-all duration-300 ease-linear"
                onClick={() => setOpenMenu(false)}
              >
                İletişim
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
