import React from 'react'
import {IoChevronForwardOutline} from 'react-icons/io5';


const RightArrow = ({onClick}) => {
  return (
    <div className='flex absolute right-[0px] top-[50%] translate-y-[-50%] w-fit z-40'>
      <button className='text-[70px] font-bold' onClick={onClick}>
        {/* <IoChevronForwardOutline className='text-[#063454]'/> */}
        <IoChevronForwardOutline className='text-yellow-600 '/>

      </button>
    </div>
  )
}

export default RightArrow