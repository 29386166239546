import React from 'react'

const HomeHakkımızda = () => {
  return (
    <div className='bg-slate-200 py-5 px-2'>
      {/* <h1>HAKKIMIZDA</h1> */}
        <ul >
          
        </ul>
        
        </div>
  )
}

export default HomeHakkımızda