export const products = [
  {
    id: 1,
    img: '/images/500mlmadeni.png',
    title: '500 ml Bidon',
    section: ['Madeni']
  },
  {
    id: 2,
    img: '/images/500mltarımkimyasal.png',
    title: '500 ml Tarım ve Kimyasal',
    section: ['Tarim', 'Endüstriyel']
  },
  {
    id: 3,
    img: '/images/1ltmadeni.png',
    title: '1 lt Madeni Yağ Bidonu',
    section: ['Madeni']
  },
  {
    id: 4,
    img: '/images/1lt.png',
    title: '1 lt Yağ Bidonu',
    section: ['Madeni']
  },

  {
    id: 5,
    img: '/images/1ltkimyasal.png',
    title: '1 lt Kimyasal ve Temizlik Bidonu',
    section: ['Endüstriyel']
  },
  {
    id: 6,
    img: '/images/1ltmadenitarımkimyasal.png',
    title: '1 lt Madeni Yağ,Tarım ve Kimyasal Bidonu',
    section: ['Madeni', 'Tarim', 'Endüstriyel']
  },
  {
    id: 7,
    img: '/images/3ltmadeni.png',
    title: '3 lt Madeni Yağ ve Antifriz Bidonu',
    section: ['Madeni']
  },
  {
    id: 8,
    img: '/images/3ltmadeniyag.png',
    title: '3 lt Madeni Yağ Bidonu',
    section: ['Madeni']
  },
  {
    id: 9,
    img: '/images/5ltendüstriyel.png',
    title: '5 lt Cam Suyu ve Kimyasal Bidonu',
    section: ['Endüstriyel']
  },
  {
    id: 10,
    img: '/images/5ltmadeni.png',
    title: '5 lt Gagalı AdBlue Bidonu',
    section: ['Madeni']
  },
  {
    id: 16,
    img: '/images/5ltKoseliBidon.png',
    title: '5 lt Köşeli Çanta Bidon',
    section: ['Endüstriyel']
  },
  {
    id: 11,
    img: '/images/7ltmadeni.png',
    title: '7 lt Madeni Yağ Bidonu',
    section: ['Madeni']
  },
  {
    id: 12,
    img: '/images/10ltmadenikimyasal.png',
    title: '10 lt Madeni Yağ ve Kimyasal Bidonu',
    section: ['Madeni', 'Endüstriyel']
  },
  {
    id: 13,
    img: '/images/10ltmadeni.png',
    title: '10 lt Gagalı AdBlue Bidonu',
    section: ['Madeni']
  },
  {
    id: 14,
    img: '/images/20ltmadenikimyasal.png',
    title: '20 lt Madeni Yağ, AdBlue ve Kimyasal Bidonu',
    section: ['Madeni', 'Endüstriyel']
  },
  {
    id: 15,
    img: '/images/30ltmadeni.png',
    title: '30 lt Madeni Yağ, AdBlue ve Kimyasal Bidonu',
    section: ['Madeni', 'Endüstriyel']
  }
]

export const productTopics = [
    {
        id:1,
        img:"/images/madeni_Yag.jpeg",
        title:"MADENİ YAĞ PLASTİK",
        section:"Madeni",
    },
    {
        id:2,
        img:"/images/gida_plastik.jpeg",
        title:"ZİRAİ PLASTİK ŞİŞELERİ",
        section:"Tarim",
    },
    {
        id:3,
        img:"/images/endüstriyel.jpeg",
        title:"ENDÜSTRİYEL PLASTİK",
        section:"Endüstriyel",
    },
    {
        id:4,
        img:"/images/zirai.jpeg",
        title:"GIDA PLASTİK ŞİŞELERİ",
        section:"Gida",
    },
   
]


