
import React from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import HomeHakkımızda from '../components/HomeHakkımızda';
import HomeProducts from '../components/HomeProducts';
import Header from '../components/Header';
import Hikayemiz from '../components/Hikayemiz';
import DogaDostu from '../components/DogaDostu';
import Header2 from '../components/Header2';

const Home = () => {
  return (
    <>
    <Navbar/>
    <div className='hidden lg:block'>
      <Header />
    </div>
    <div className='lg:hidden'>
      <Header2/>

    </div>
     {/* <img src="/images/header.png" alt="header" /> */}
      <HomeProducts/>
      <Hikayemiz/>
      <DogaDostu/>
      <HomeHakkımızda/>
      <Footer/>
    </>
  );
};

export default Home;