import React from 'react'

const SingleProductForProducts = ({item}) => {
  return (
    <div className='cursor-pointer w-[270px] m-auto mt-4 shadow-xl rounded-xl bg-white'>
    <img src={item.img} alt={item.title} className='w-full h-[300px] object-cover'/>
    <h1 className='uppercase text-center font-bold  py-3 min-h-[72px]'>{item.title}</h1>
</div>
  )
}

export default SingleProductForProducts