import React from 'react'
import SingleProduct from './SingleProduct';
import { productTopics, products } from '../utils/data';

const HomeProducts = () => {
   
    
  return (
    <div className='m-auto px-12 md:px-[110px] text-justify pt-12 pb-6 home-product relative'>
   

     
    <h1 className='text-center text-2xl sm:text-4xl md:text-5xl pb-4 font-semibold  text-[#063454] different_font relative z-20'>SİZİN İÇİN YAPABİLECEKLERİMİZ</h1>
    <div className='flex flex-col md:flex-row justify-center md:items-center md:py-4 w-full relative z-20'>
            {productTopics?.map((item,index) => {
              return (
                <ul className="m-0" key={item.id}>
                <li className="list-unstyled "><SingleProduct item={item}  index={index}/></li>
                </ul>
                );
              })}
    </div>
    <div className='absolute w-full h-full opacity-60 top-0 left-0 bg-slate-500'>
    </div> 
</div>
  )
}

export default HomeProducts