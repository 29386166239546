import React from "react";

const DogaDostu = () => {
  return (
    <div className="px-2 py-4 bg-slate-200">
      <h1 className='text-center text-2xl sm:text-4xl md:text-4xl py-4 font-bold  text-white bg-[#063454] max-w-[700px] m-auto'>DOĞA DOSTU PLASTİK AMBALAJ</h1>
      <ul>
        <li className="list-none list-inside pt-12 max-w-[800px] m-auto text-justify">
        Hedefini değişmez kalite yaklaşımı ve koşulsuz müşteri memnuniyeti ile çağın yeniliklerine ayak uyduran ve gelişen teknoloji ile size daha iyi bir kalite sunabilme perspektifi ortaya koyan bir gelecek vizyonu…
        </li>
      </ul>
    </div>
  );
};

export default DogaDostu;
