import React from "react";
import { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="navbar-bg">
      <Navbar />
      <div className="bg-slate-200">
        <div className="container text-justify m-auto px-3 py-12 pt-[120px] min-h-[calc(100vh-236px)] max-w-[1000px]">
          <h1 className="text-center text-5xl pb-4 font-semibold text-[#063454]">
            HAKKIMIZDA
          </h1>
          <br />
          <p className="nocopy_paste">
            Kırcalı Plastik, kurulduğu günden bugüne kalite ve hizmetten taviz
            vermeyerek gelişmeye yönelik hedefleri doğrultusunda plastik şişirme
            ve enjeksiyon sektöründe kalite ve hijyen standartlarının üst
            seviyelerine ulaşmıştır. Şirketimiz toplam kalite felsefesi ışığında
            alt yapısını ileri teknoloji düzeyinde hazırlayarak, farklı
            sektörlere hizmet vermek için yatırımlar yapmıştır. Günümüzde ise
            madeni Zirai Endüstriyel ve Gıda sektörlerine yönelik birçok plastik
            şişe çeşidini en yeni teknolojiler ve akıllı çözümler ile üretebilir
            hale gelmiştir.
          </p>
          <br />
          <img src="/images/hakkımızda.jpeg" alt="hakkımızda" className="rounded-[50px]"/>
          <p className="nocopy_paste">
          <br />
            Çağın getirdiği modern işletmecilik anlayışımız, güçlü ve kaliteli
            makine parkurumuz, konusunda deneyimli personelimiz ve tecrübemiz
            ile müşterilerimizin yanındayız. Hedefimiz, sürekli gelişme ve
            başarıyı amaçlayan bir takım ruhu ile, Türk plastik sektörüne
            gelişen teknoloji paralelinde kaliteli, hızlı, güvenilir ve
            rekabetçi ürünler sunmaktır.
          </p>
          <br />
          <p className="nocopy_paste">
          Her şey karşılıklı güven sayesinde...
          </p>
        </div>
      </div>
      <div className="p-6 bg-slate-200">
        <h1 className="text-center font-bold text-3xl lg:text-4xl text-[#063454] pb-10">SERTİFİKALARIMIZ</h1>
        <div className="flex flex-col xl:flex-row justify-center items-center gap-5">
        <img src="/images/sertifika_1.png" alt="sertifika" className="w-full max-w-[550px] object-contain"/>
        <img src="/images/sertifika_2.png" alt="sertifika" className="w-full max-w-[550px] object-contain"/>
      </div>
      </div>
      
      <Footer />
    </div>
  );
};

export default About;
