import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

const SingleProduct = ({item}) => {
  const [rotate,setRotate]=useState(false)
  console.log(item)

  return (
    <div className={`${rotate ? " w-full md:w-[400px]   bg-slate-500" : "w-full md:w-[150px]  bg-slate-500 " }  cursor-pointer relative h-[110px] md:h-[450px] flex items-center justify-center transition-all duration-500 ease-linear`} 
    onMouseEnter={()=>setRotate(true)}
    onMouseLeave={()=>setRotate(false)}>
      <NavLink to={item.section=="Madeni" ? "/madeni_yag_plastik" : item.section=="Tarim" ? "/zirai_plastik" : item.section=="Gida" ? "/gida_plastik"  :"/endustriyel_plastik"} className="block w-full h-full flex items-center justify-center" >
         <img src={item.img} className={`${rotate ? "grayscale-0 " : "grayscale "} object-cover w-full h-full absolute  `} />
    <h1 className={`${rotate ? "md:rotate-0 opacity-100 md:border border-yellow-600" : "md:-rotate-90  opacity-70 border-0 "} uppercase  font-bold  w-full flex justify-center items-center test
     text-yellow-500  text-3xl  rounded-md transition-all duration-500 ease-linear text-center `} >{item.title}</h1>
      </NavLink>
     
</div>
  )
}

export default SingleProduct