import React from "react";
import LeftArrow from "./LeftArrow";
import RightArrow from "./RightArrow";
import Slider from "react-slick";

const Header = () => {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    arrows: true,
    prevArrow: <LeftArrow />,
    nextArrow: <RightArrow />,
  };
  return (
    <>
    <div>
      <Slider {...settings} className="m-auto">
        <div className="slide-1  min-h-[640px] justify-center items-center overflow-visible relative">
          <div className="flex justify-center items-center overflow-visible">
            <p className="absolute top-[50%] left-[10%] translate-y-[-50%] font-bold text-[60px] leading-[70px]  test text-white">Doğanın<br/> Hep Yanındayız...</p>
            <img
              src="/images/tree.webp"
              alt="tree"
              className="lg:absolute z-20 max-w-[700px] top-[170px] right-0 hidden lg:block"
            />
          </div>
        </div>
        <div className="slide-2  min-h-[640px] flex justify-center items-center overflow-visible relative ">
          <div className="flex justify-center items-center overflow-visible">
            <p className="absolute top-[55%] translate-y-[-50%] left-[10%]  font-bold text-[80px] leading-[80px] text-white test"> Plastik <br/> Sanatı...</p>
         
          </div>
        </div>
        <div className="slide-3  min-h-[640px] flex justify-center items-center overflow-visible relative ">
          <div className="flex justify-center items-center overflow-visible">
          <p className="absolute top-[55%] left-[10%] translate-y-[-50%] font-bold text-[60px] leading-[70px] text-white test"> Sektörünün <br/> Yeni Yüzü...</p>
            <img
              src="/images/s_3.webp"
              alt="tree"
              className="lg:absolute z-20 w-full max-w-[400px] top-[250px] right-[5%] hidden lg:block"
            />
          </div>
        </div>
      </Slider>
    </div>

    </>
  );
};

export default Header;
