import React from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Team = () => {
  return (
    <div className='navbar-bg'>
    <Navbar />
    <div className="flex flex-col items-center justify-center max-w-[600px] text-center min-h-[calc(100vh-146px)] m-auto pt-[120px]">
    <img
      src="/images/male_user.png"
      alt="female_attorney"
      className="w-[160px] rounded-full border-[3px] border-[#063454] object-cover cursor-pointer "
    />
    <h1 className='font-bold py-2 cursor-pointer text-[#063454]'>Sadullah ŞENTÜRK</h1>
    <p>
    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam doloremque temporibus nesciunt nemo iure, quia a accusamus esse alias impedit.
    </p>
  </div>
    <Footer/>
  </div>
  )
}

export default Team