import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import SingleProductForProducts from '../components/SingleProductForProducts'
import { products } from '../utils/data'

const MadeniYag = () => {
    let madeniUrunler=products?.filter(item=>item.section.includes("Madeni"))
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='navbar-bg'>
    <Navbar />
    <div className="bg-slate-200">

    
<div className="flex flex-col flex-wrap justify-center items-center max-w-[1200px] m-auto py-12 min-h-[calc(100vh-236px)] pt-[120px]">
<h1 className="text-center text-3xl sm:text-5xl pb-4 font-semibold  text-[#063454]">
 MADENİ YAĞ PLASTİK ÜRÜNLERİMİZ
</h1>
<div className="flex  flex-wrap justify-center items-center ">
    {madeniUrunler?.map((item,index) => {
        return (
          <ul className="m-auto " key={item.id}>
          <li className="list-unstyled mx-1 "><SingleProductForProducts item={item}  index={index}/></li>
          </ul>
          );
        })}
</div>

</div>
<li className='list-none list-inside py-6 max-w-[750px] m-auto text-xl font-semibold text-center'> 500 ml den 30 lt'ye kadar üretim talep edildiğinde özel üretim de yapılır.</li>
</div>
    <Footer/>
  </div>
  )
}

export default MadeniYag