import React from "react";

const Hikayemiz = () => {
  return (
    <div className="bg-slate-200 py-10 px-2">
      <h1 className='text-center text-2xl sm:text-4xl md:text-5xl pb-4 font-semibold  text-[#063454] '>HİKAYEMİZ <br/> KIRCALI PLASTİK</h1>
      <ul>
        <li className="list-none list-inside pt-2 max-w-[800px] m-auto text-justify">
          Kırcalı Plastik, kurulduğu günden beri kalite ve hizmetten taviz
          vermeyerek gelişmeye yönelik hedefleri doğrultusunda plastik şişirme
          ve enjeksiyon sektöründe kalite ve hijyen standartlarının üst
          seviyelerine ulaşmıştır. Plastik Bidon üretimi yapan firmamız,
          kaliteli bidon felsefesi ışığında alt yapısını ileri teknoloji
          kullanarak sizlere hizmet vermek için çalışmaktadır. Günümüzde ise
          tarım, gıda, medikal, ev ve kişisel bakım ürünleri, beşeri ve
          veteriner ilaçlar, madeni yağ ve otomotiv sektörlerine yönelik birçok
          ambalaj çeşidini üretebilir hale gelmiştir.<br/><br/> Çağın getirdiği modern
          işletmecilik anlayışımız, güçlü ve kaliteli makineler ve konusunda
          deneyimli personel ile tecrübelimizi aktarmak için müşterilerimizin
          Kırcalı Plastik olarak yanındayız. Hedefimiz, sürekli gelişme ve
          başarıyı amaçlayan bir takım ruhu ile, Türk plastik sektörüne gelişen
          teknoloji paralelinde kaliteli, hızlı, güvenilir ve rekabetçi ürünler
          sunmaktır.<br/><br/>  Her şey karşılıklı güven sayesinde.
        </li>
      </ul>
    </div>
  );
};

export default Hikayemiz;
