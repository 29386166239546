import React from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import SingleProduct from '../components/SingleProduct';
import { products } from '../utils/data';
import SingleProductForProducts from '../components/SingleProductForProducts';

const Products = () => {
  return (
   <div className='navbar-bg'>
    <Navbar />
    <div className="bg-slate-200">

    
<div className="flex flex-col flex-wrap justify-center items-center max-w-[1200px] m-auto py-12 min-h-[calc(100vh-236px)] pt-[120px]">
<h1 className="text-center text-3xl sm:text-5xl pb-4 font-semibold  text-[#063454]">
 TÜM ÜRÜNLERİMİZ
</h1>
<div className="flex  flex-wrap justify-center items-center ">
    {products?.map((item,index) => {
        return (
          <ul className="m-auto " key={item.id}>
          <li className="list-unstyled mx-1 "><SingleProductForProducts item={item}  index={index}/></li>
          </ul>
          );
        })}
</div>

</div>
<li className='list-none list-inside py-6 max-w-[750px] m-auto text-xl font-semibold text-center'> 500 ml den 30 lt'ye kadar üretim talep edildiğinde özel üretim de yapılır.</li>
</div>
    <Footer/>
  </div>
  )
}

export default Products