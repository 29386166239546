import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import About from '../pages/About';
import Products from '../pages/Products';
import Team from '../pages/Team';
import Contact from '../pages/Contact';
import MadeniYag from '../pages/MadeniYag';
import ZiraiPlastik from '../pages/ZiraiPlastik';
import EndustriyelPlastik from '../pages/EndustriyelPlastik';
import GıdaPlastik from '../pages/GıdaPlastik';


const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/hakkimizda" element={<About/>} />
        <Route path="/urunlerimiz" element={<Products/>} />
        <Route path="/ekibimiz" element={<Team/>} />
        <Route path="/iletisim" element={<Contact/>} />
        <Route path="/madeni_yag_plastik" element={<MadeniYag/>} />
        <Route path="/zirai_plastik" element={<ZiraiPlastik/>} />
        <Route path="/endustriyel_plastik" element={<EndustriyelPlastik/>} />
        <Route path="/gida_plastik" element={<GıdaPlastik/>} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
