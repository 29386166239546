import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const GıdaPlastik = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='navbar-bg'>
    <Navbar/>
    <div className="flex flex-col items-center justify-center max-w-[600px] text-center min-h-[calc(100vh-146px)] m-auto pt-[120px]">
   ÜRÜNLERİMİZ YAKINDA YÜKLENECEKTİR
  </div>
    <Footer/>
  </div>
  )
}

export default GıdaPlastik